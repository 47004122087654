// This file configures the initialization of Sentry on the browser,
// or on the server. The config you add here will be used whenever:
// - a page is visited
// - the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { ExtraErrorData } from '@sentry/integrations';

const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development';
const SKIP_CAPTURE = ENVIRONMENT === 'development';

const ignoreErrors = [
  // Ignore http://bit.ly/2hk42Ap, resulting from what
  // appears to be a Chrome bug http://bit.ly/2gVbncS
  /__gCrWeb\.autofill\.extractForms/,
  // Ignore http://bit.ly/2hk71Zv, resulting from
  // some really strange issue in Safari 9. Experienced
  // by Mozilla team, too http://bit.ly/2hk9vHq
  /docs-homescreen-gb-container/,
  // Ignore http://bit.ly/2mVGcgX. It's a weird react error.
  // Google says it _might_ be a logging error? http://bit.ly/2lEB3ZW
  /e\.getHostNode/,
  // Ignore http://bit.ly/2lyZSao, resulting from
  // some Chrome plugin presumably.
  /ahk_main/,
  // Some weird firefox errors that seem to happen only on firefox for iOS.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1394296
  /__firefox__\./,
];

function censorSensitiveInformation(event) {
  const keys = ['password', 'secret', /token$/i, 'credentials'];

  for (const eventKey in event) {
    const removeKey = keys.reduce(
      (result, key) => result || eventKey.match(key) !== null,
      false
    );

    if (removeKey) {
      delete event[eventKey];
    } else if (typeof event[eventKey] === 'object') {
      censorSensitiveInformation(event[eventKey]);
    }
  }
}

export default function initializeSentry({ dsn }) {
  // Cannot initialize Sentry without a dsn
  if (!dsn) {
    return;
  }

  Sentry.init({
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    dsn,

    environment: ENVIRONMENT,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,

    integrations: [
      // Extracts all non-native attributes from the Error object and attaches
      // them to the event as the `extra` data.
      // https://docs.sentry.io/platforms/javascript/#extraerrordata
      new ExtraErrorData(),
    ],

    beforeSend(event) {
      let ignore = false;

      if (event.exception) {
        const values = event.exception?.values || [];
        values.forEach((exception) => {
          ignoreErrors.forEach((error) => {
            ignore = ignore || exception?.value?.match(error) !== null;
          });
        });
      }

      if (ignore) {
        return null;
      }

      censorSensitiveInformation(event);

      if (SKIP_CAPTURE) {
        /* eslint-disable no-console */
        console.info('Would have captured exception', event);
        return null;
      }

      return event;
    },
  });
}
