import { MachineOptions } from 'xstate';

import { Context, EventOfType } from './types';

type MachineGuards = MachineOptions<Context, any>['guards'];

const guards: MachineGuards = {
  isLoggedIn: (_, { data }: EventOfType<'done.invoke.getSession'>) =>
    data.isLoggedIn && !!data.customUserId,
};

export default guards;
