import { Fragment, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { emit } from '@zapier/events-toolkit-browser';
import getReleaseId from '../../utils/getReleaseId';

type Props = {
  children: ReactNode;
};

export function TrackRouting({ children }: Props) {
  const { asPath: path } = useRouter();

  useEffect(() => {
    // Only track on the client side
    // and do not track the pre-hydrated path that doesn't include the
    // real params (i.e. [accountId])
    if (
      typeof window !== 'undefined' &&
      !(path.includes('[') && path.includes(']'))
    ) {
      // We only need to specify the system name that should be used for the PageViewEvent.
      // The other PageViewEvent fields are set by automatically by events-toolkit-browser
      // and the v4 Events API.
      emit(
        {
          release_id: getReleaseId(),
          system_name: 'transfer',
        },
        'web_analytics.tracking.page_view.PageViewEvent'
      );
    }
  }, [path]);

  return <Fragment>{children}</Fragment>;
}
