import { datadogRum } from '@datadog/browser-rum';
import type { NextWebVitalsMetric } from 'next/app';

const ENABLE_DATADOG_TRACKING =
  process.env.ENABLE_DATADOG_TRACKING ||
  process.env.NEXT_PUBLIC_ENABLE_DATADOG_TRACKING ||
  false;

const shouldTrackToDatadog = ENABLE_DATADOG_TRACKING === 'true';

export function initializeDatadogRum() {
  // Don't initialize during SSR
  if (typeof window === 'undefined') {
    return;
  }

  const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
  const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  if (!applicationId || !clientToken) {
    // eslint-disable-next-line no-console
    console.info(
      'Datadog RUM not initialized: missing applicationId or clientToken.'
    );
    return;
  }

  if (!shouldTrackToDatadog) {
    // eslint-disable-next-line no-console
    console.info(
      'Datadog RUM not initialized: not in a production environment.'
    );
    return;
  }

  // See https://docs.datadoghq.com/real_user_monitoring/installation/?tab=us#initialization-parameters
  // for more information on what these individual parameters do.
  datadogRum.init({
    applicationId,
    clientToken,
    sampleRate: 100,
    site: 'datadoghq.com',
    service: 'transfer-frontend',
    env: process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV,
    trackInteractions: true,
    version:
      process.env.VERCEL_GIT_COMMIT_SHA ||
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  });
}

const vitalsMapper: { [key: string]: string } = {
  // Length of time it takes for the page to start and finish hydrating (in ms)
  'Next.js-hydration': 'nextJsHydration',
  // Length of time it takes for a page to start rendering after a route change (in ms)
  'Next.js-route-change-to-render': 'nextJsRouteChangeToRender',
  // Length of time it takes for a page to finish render after a route change (in ms)
  'Next.js-render': 'nextJsRender',

  // Web Vitals - https://web.dev/vitals/
  CLS: 'cumulativeLayoutShift',
  FCP: 'firstContentfulPaint',
  FID: 'firstInputDelay',
  LCP: 'largestContentfulPaint',
  TTFB: 'timeToFirstByte',
};

// Measure and report web vitals that Next.js tracks to Datadog.
// See: https://nextjs.org/docs/advanced-features/measuring-performance
export function nextJsReportWebVitals(metric: NextWebVitalsMetric) {
  const mappedName = vitalsMapper[metric.name] || metric.name;

  if (!shouldTrackToDatadog) {
    // eslint-disable-next-line no-console
    console.info(
      `Datadog RUM: would have tracked web vital ${mappedName} - ${metric.value}.`
    );
    return;
  }

  datadogRum.addUserAction(`Web Vital: ${mappedName}`, {
    [mappedName]: metric.value,
  });
}
