import type { AppProps } from 'next/app';

import AppController from '../controllers/App';

import { initializeDatadogRum, nextJsReportWebVitals } from '../observability';
import Version from '../components/Version';

import {
  GlobalStylesProvider,
  ZinniaFonts,
  ZinniaStylesReset,
} from '@zapier/design-system';
import '@zapier/design-tokens/src/css/custom-properties.css';

// Measure and report web vitals that Next.js tracks to Datadog.
// See: https://nextjs.org/docs/advanced-features/measuring-performance
initializeDatadogRum();
export const reportWebVitals = nextJsReportWebVitals;

function App({ Component, pageProps, err }: AppProps & { err: unknown }) {
  return (
    <GlobalStylesProvider isPlatform={true} isRebrand={true}>
      <ZinniaFonts />
      <ZinniaStylesReset />
      <AppController>
        <Component {...pageProps} err={err} />
        <Version />
      </AppController>
    </GlobalStylesProvider>
  );
}

export default App;
