import { SplitFactory } from '@splitsoftware/splitio-react';
import { useService } from '@xstate/react';
import { Fragment, FC, useMemo } from 'react';

import { Service as AppService } from '../machines/app/types';

type Props = { service: AppService };

const useSplitConfig = (key: any) =>
  useMemo(
    () => ({
      core: {
        authorizationKey: process.env.NEXT_PUBLIC_SPLIT_API_KEY as string,
        key: String(key),
      },
    }),
    [key]
  );

const SplitProvider: FC<Props> = ({ service, children }) => {
  const [{ context }] = useService(service);
  const config = useSplitConfig(context.session?.customUserId);

  // Don't instantiate the SplitFactory until we know the user is either logged in or logged out.
  // Re-rendering the SplitFactory with a different config seems to result in incorrect Split checks.
  if (context.session) {
    return (
      <SplitFactory config={config} updateOnSdkUpdate={true}>
        <Fragment>{children}</Fragment>
      </SplitFactory>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default SplitProvider;
