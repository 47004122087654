import { MachineOptions } from 'xstate';
import type { ZapierSession } from '@zapier/universal-layout';

import { login } from '../../utils/oauth';
import { zapierClient, transferClient } from '../../utils/client';
import type { Context, Events } from './types';

type MachineServices = MachineOptions<Context, Events>['services'];

const services: MachineServices = {
  getSession: async (): Promise<ZapierSession> => {
    const zapSesh = await zapierClient.getSession();
    if (!zapSesh.isLoggedIn) {
      return zapSesh;
    }

    const txSesh = await transferClient.getSession({});
    if (!txSesh.id) {
      return zapSesh;
    }

    // if we end up with a Zapier session that does not
    // match the Transfer session, we should reauthenticate
    if (zapSesh.customUserId !== txSesh.zapier_customuser_id) {
      await Promise.all([transferClient.logout(), zapierClient.logout()]);
      login();
    }

    return zapSesh;
  },

  getAccounts: () => zapierClient.getAccounts(),
};

export default services;
