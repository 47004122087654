import { assign, MachineOptions } from 'xstate';

import { Context, EventOfType } from './types';
import { login } from '../../utils/oauth';

type MachineActions = MachineOptions<Context, any>['actions'];

const actions: MachineActions = {
  redirectToLogin: login,

  setSession: assign<Context, EventOfType<'done.invoke.getSession'>>({
    session: (_, { data }) => data,
  }),

  setAccounts: assign<Context, EventOfType<'done.invoke.getAccounts'>>({
    accounts: (_, { data }) => data,
  }),
};

export default actions;
