import * as React from 'react';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'react-use';
import { v4 as uuid } from 'uuid';

const INTERACTION_ID_KEY = '_transfer_interaction';

const INITIAL_INTERACTION_ID = uuid();

/**
 * We'll persist a unique identifier to localStorage so
 * we can reuse that identifier across the various pages
 * of creating, setting up, and using a new Transfer Zap.
 * Anytime the user accesses the "/new" page, we'll create
 * a new unique identifier, treating it as a new interaction.
 */
export function useInteractionId() {
  const { pathname } = useRouter();

  const [interactionId, persistInteractionId] = useLocalStorage(
    INTERACTION_ID_KEY,
    INITIAL_INTERACTION_ID
  );

  const setInteractionId = React.useCallback(
    (id: string) => persistInteractionId(id),
    [persistInteractionId]
  );

  React.useEffect(() => {
    if (pathname === '/new') {
      setInteractionId(uuid());
    }
  }, [pathname, setInteractionId]);

  return interactionId!;
}
