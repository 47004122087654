/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Text } from '@zapier/design-system';

export default function Version() {
  return (
    <div
      aria-hidden={true}
      css={css`
        position: fixed;
        bottom: 4px;
        left: 6px;
      `}
    >
      <Text color="neutral600" type="smallPrint3">
        ver: {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA!.substring(0, 9)}
      </Text>
    </div>
  );
}
