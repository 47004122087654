import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { UniversalLayout } from '@zapier/universal-layout';
import { CenteredLoading } from '@zapier/transfer-components';

import { useInteractionId } from './useInteractionId';
import useAppMachine from '../machines/app';
import { AppContextProvider } from '../context/appContext';
import { TrackingProvider } from '../observability/tracking';
import { TrackRouting } from '../observability/tracking/TrackRouting';
import SplitProvider from '../components/SplitProvider';
import { zapierUrl } from '../utils/urls';

const ZAPIER_ORIGIN = process.env.NEXT_PUBLIC_ZAPIER_ORIGIN;

const AppLayout: FC = ({ children }) => {
  const interactionId = useInteractionId();
  const [{ context }] = useAppMachine(interactionId);
  const { session, accounts } = context;

  return (
    <UniversalLayout
      accountsListData={accounts}
      apiHostname={ZAPIER_ORIGIN}
      currentUrl={zapierUrl('app', 'transfers')}
      mainContentId=""
      mode="in-app"
      renderAlertBanner={() => null}
      sessionData={session}
      showSimplifiedFooter={true}
      trackEvent={() => {}}
      urlPathPrefix={ZAPIER_ORIGIN}
    >
      {children}
    </UniversalLayout>
  );
};

const AppController: FC = ({ children }) => {
  const { isReady } = useRouter();
  const interactionId = useInteractionId();
  const [, , service] = useAppMachine(interactionId);

  if (!isReady) {
    return <CenteredLoading />;
  }

  return (
    <TrackingProvider interactionId={interactionId}>
      <TrackRouting>
        <SplitProvider service={service}>
          <AppContextProvider value={service}>
            <AppLayout>{children}</AppLayout>
          </AppContextProvider>
        </SplitProvider>
      </TrackRouting>
    </TrackingProvider>
  );
};

export default AppController;
