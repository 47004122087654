import { StateNodeConfig } from 'xstate';

import { Context, Events, StateSchema } from './types';

const config: StateNodeConfig<Context, StateSchema, Events> = {
  strict: true,
  id: 'app',
  initial: 'session',
  states: {
    session: {
      invoke: {
        id: 'getSession',
        src: 'getSession',
        onDone: [
          {
            cond: 'isLoggedIn',
            target: 'accounts',
            actions: 'setSession',
          },
          {
            actions: 'redirectToLogin',
          },
        ],
        onError: {
          actions: 'redirectToLogin',
        },
      },
    },
    accounts: {
      invoke: {
        id: 'getAccounts',
        src: 'getAccounts',
        onDone: {
          target: 'loggedIn',
          actions: 'setAccounts',
        },
        onError: {
          actions: 'redirectToLogin',
        },
      },
    },
    loggedIn: {
      type: 'final',
    },
  },
};

export default config;
