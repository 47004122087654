import { createMachine } from 'xstate';
import { useMachine } from '@xstate/react';

import config from './config';
import actions from './actions';
import guards from './guards';
import services from './services';

import { DEBUG_ENABLED } from '../../constants';
import { useRouter } from 'next/router';
import { useFullStory } from '../../observability/fullStory';

const machine = createMachine(config, {
  guards,
  services,
  actions,
});

const useAppMachine = (interactionId: string) => {
  const { pathname } = useRouter();

  const instance = useMachine(machine, {
    devTools: DEBUG_ENABLED,
    context: {
      interactionId,
      route: pathname,
    },
  });

  useFullStory(interactionId, instance[0].context.session);

  return instance;
};

export default useAppMachine;
