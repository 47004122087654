import { addParamsToUrl } from '@zapier/url-utils';
import { localUrl, zapierUrl } from './urls';

export const redirectUrl = localUrl('oauth', 'complete', '');

/** Redirect to Zapier's oauth login form. */
export const login = () => {
  window.location.assign(
    addParamsToUrl(zapierUrl('oauth', 'authorize'), {
      client_id: process.env.NEXT_PUBLIC_OAUTH_ID,
      redirect_uri: redirectUrl,
      response_type: 'code',
      scope: 'internal profile',
      state: window.location.href,
    })
  );
};

/** Redirect to Zapier's signup form. */
export const signup = () => {
  window.location.assign(
    addParamsToUrl(zapierUrl('sign-up'), {
      next: window.location.toString(),
    })
  );
};
